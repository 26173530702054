<template>
  <svg v-bind="attr">
    <title v-if="image && title">{{ title }}</title>
    <use :href="`${publicPath}assets/images/${file}.svg#${icon}`" />
  </svg>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  icon: { type: String, required: true },
  file: { type: String, default: 'sprite' },
  image: { type: Boolean, default: false },
  title: { type: String, default: '' }
})
const publicPath = process.env.BASE_URL

const attr = computed(() => props.image ? { role: 'img' } : { 'aria-hidden': true })
</script>
