<template>
  <header class="header header--light header--premium sticky">
    <div class="header__wrapper">
      <div class="header__logo">
        <router-link to="/">
          <app-image
            class="header__logo-img"
            width="225"
            height="40"
            image="logo-dark.svg"
            title="Logo"
          />
        </router-link>
      </div>
      <div
        v-if="questionnaire"
        class="header__premium"
      >
        Envanter:
        <span class="header__premium-questionnaire">
          <svg-sprite icon="icon-premium" />
          <span>{{ questionnaire }}</span>
        </span>
      </div>
    </div>
  </header>
</template>

<script setup>
import SvgSprite from '@/components/ui/SvgSprite'
import AppImage from '@/components/ui/AppImage'
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()
const questionnaire = computed(() => store.getters['questionnaire/questionnaire'])
</script>
