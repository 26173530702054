<template>
  <header class="user-header sticky">
    <div class="user-header__wrapper">
      <div class="header__logo">
        <router-link to="/">
          <app-image
            class="header__logo-img"
            width="225"
            height="40"
            image="logo-dark.svg"
            title="Logo"
          />
        </router-link>
      </div>
      <mega-menu :links="links" />
      <div
        class="user-header__action-wrapper"
      >
        <router-link
          :to="{ name: 'Cart' }"
          class="btn btn--primary btn--square btn--icon"
        >
          <svg-sprite
            class="btn-icon"
            icon="icon-cart"
          />
          <span class="btn-badge">{{ cartQuantity }}</span>
        </router-link>
        <action-button
          class="btn--icon dropdown-wrapper"
        >
          <dropdown-item
            :link="link"
            class-list="user-header__nav-link"
          >
            <span class="material-icons-outlined btn-icon">account_circle</span>
          </dropdown-item>
        </action-button>
      </div>
    </div>
  </header>
</template>

<script setup>
import ActionButton from '@/components/ui/ActionButton'
import DropdownItem from '@/components/DropdownItem'
import MegaMenu from '@/components/ui/megaMenu/AppMenu'
import SvgSprite from '@/components/ui/SvgSprite'
import AppImage from '@/components/ui/AppImage'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { computed, reactive, watch } from 'vue'

defineProps({
  links: { type: Array, default: () => [] },
  socialMedia: { type: Array, default: () => [] },
  barNav: { type: Boolean, default: false }
})

const store = useStore()
const router = useRouter()
const cartQuantity = computed(() => store.getters['cart/cartQuantity'])
const user = computed(() => store.getters['auth/user'])
async function logout () {
  await router.push('/')
  await store.dispatch('auth/logout', { id: user.value.id, type: 'Logout' })
}

const link = reactive({
  title: user.value.name + ' ' + user.value.lastName,
  children: [
    {
      title: 'Çıkış',
      action: logout,
      children: false
    }
  ]
})
watch(user, () => {
  link.title = user.value.name + ' ' + user.value.lastName
})
</script>
