<template>
  <div class="menu__mega-col menu__mega-col--page">
    <div
      v-for="(child, i) in pages"
      :key="i"
      class="page-wrapper"
    >
      <h3 class="page-title">
        {{ child.title }}
      </h3>
      <ul>
        <li
          v-for="(page, j) in child.pages"
          :key="j"
        >
          <router-link
            v-if="page.url"
            class="menu__mega-item"
            :to="page.url"
            @click="$emit('close')"
          >
            <h3 class="menu__mega-item-title">
              {{ page.title }}
            </h3>
            <span
              v-if="page.description"
              class="menu__mega-item-description"
            >{{ page.description }}</span>
          </router-link>
          <span
            v-if="page.action"
            class="menu__mega-item-btn"
            @click="page.action"
          >
            {{ page.title }}
          </span>
        </li>
      </ul>
    </div>
  </div>
  <div class="menu__mega-col menu__mega-col--blog">
    <h3 class="page-title">
      Öne Çıkan Blog İçeriği
    </h3>
    <blog-card
      v-if="featuredPost"
      :item="featuredPost"
      type="menu"
      :widths="[300, 760, 1920]"
      sizes="(max-width: 560px) 270px, 348px"
      width="283"
      height="165"
    />
  </div>
</template>
<script setup>
import BlogCard from '@/components/ui/blog/BlogCard'
import { useBlog } from '@/models/blog'

defineEmits(['close'])
defineProps({
  pages: { type: Array, required: true }
})

const blog = useBlog()
const featuredPost = blog.featuredPost
</script>
