<template>
  <div
    class="sidebar"
    :class="{ 'sidebar--transparent': transparent, 'sidebar--pills': pills }"
  >
    <nav class="sidebar__nav">
      <ul
        class="sidebar__nav-list"
        ref="menuItem"
      >
        <li
          v-for="(link, i) in isMobile ? mobileLinks : links"
          :key="i"
          class="sidebar__nav-item"
        >
          <template v-if="link.children">
            <app-accordion
              :id="link.title"
              v-model="isOpen"
            >
              <template #title>
                <svg-sprite
                  v-if="link.icon"
                  class="sidebar__nav-icon"
                  :icon="link.icon"
                />
                <span>{{ isMobile ? link.shortTitle : link.title }}</span>
              </template>
              <template #content>
                <router-link
                  v-for="(item, j) in link.children"
                  :key="j"
                  class="sidebar__nav-button-link"
                  :to="'/' + item.url"
                  @click="isOpen = false"
                >
                  <svg-sprite
                    v-if="item.icon"
                    class="sidebar__nav-icon"
                    :icon="item.icon"
                  />
                  {{ isMobile ? item.shortTitle : item.title }}
                </router-link>
              </template>
            </app-accordion>
          </template>
          <template v-else>
            <router-link
              class="sidebar__nav-link"
              :to="'/' + link.url"
              :key="linkKey"
              @click="linkClick"
            >
              <svg-sprite
                v-if="link.icon"
                class="sidebar__nav-icon"
                :icon="link.icon"
              />
              <span>{{ isMobile ? link.shortTitle : link.title }}</span>
            </router-link>
          </template>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import AppAccordion from '@/components/ui/AppAccordion'
import { computed, ref, watch } from 'vue'
import SvgSprite from '@/components/ui/SvgSprite'
import { useRoute } from 'vue-router'

const props = defineProps({
  links: { type: Array, required: true },
  transparent: { type: Boolean, default: false },
  pills: { type: Boolean, default: false },
  more: { type: Boolean, default: false }
})

const isOpen = ref(false)
const linkKey = ref(0)

const linkClick = () => {
  isOpen.value = false
  linkKey.value += 1
}

const isMobile = computed(() => screen.width <= 816)
const mobileLinks = computed(() => {
  if (props.links.length > 5 && !props.more) {
    const main = props.links.slice(0, 4)
    const more = {
      title: 'Daha Fazla',
      shortTitle: 'Daha Fazla',
      url: '#',
      icon: 'icon-dots',
      children: props.links.slice(4, props.links.length)
    }
    main.push(more)
    return main
  }

  return props.links
})

const menuItem = ref()
const route = useRoute()
const computedItem = computed(() => menuItem.value?.children)

function scrollParentToChild (child, debug = false) {
  const childLeft = child.offsetLeft
  const childWidth = child.offsetWidth
  const parentWidth = child.parentNode.offsetLeft + child.parentNode.offsetWidth
  const scrollLeft = childLeft - ((parentWidth / 2) - (childWidth / 2))
  child.parentNode.scrollLeft = scrollLeft
  if (debug) console.log({ childLeft, childWidth, parentWidth, scrollLeft })
}

watch([computedItem, route], () => {
  if (isMobile.value && computedItem.value && props.more) {
    const items = Array.from(computedItem.value)
    const keys = Object.keys(items)
    const filtered = keys.filter(x => items[x].firstChild.href.includes(route.fullPath))
    const index = keys.indexOf(filtered[0])
    if (index >= 0) scrollParentToChild(items[index])
  }
}, { immediate: true, deep: true })
</script>
