import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import token from '@/graphql/refreshToken.mutation.graphql'
import store from '@/store'
import { useJwt } from '@/includes/jwt'

const getHeaders = () => {
  const headers = {}
  const token = localStorage.getItem('accessToken')
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  headers['Content-Type'] = 'application/json'
  headers['Access-Control-Allow-Origin'] = '*'
  headers['Access-Control-Request-Method'] = 'POST'
  return headers
}

const getNetworkErrors = error => error.networkError.response.json().then(e => e.errors.map(e => e.message).join(','))

async function refreshToken () {
  return await apolloClient.mutate({ mutation: token })
    .then((res) => {
      const { decodedJwt } = useJwt(res.data?.refreshToken.accessToken)
      const expiresDate = +decodedJwt.exp * 1000
      localStorage.setItem('user', JSON.stringify(decodedJwt.user))
      localStorage.setItem('accessToken', res.data?.refreshToken.accessToken)
      localStorage.setItem('expires', expiresDate)
      store.dispatch('auth/autoLogin')
      return res.data
    })
    .catch((e) => {
      if (e.networkError) {
        getNetworkErrors(e).then(console.log)
      } else if (e.message === 'Invalid fingerprint') {
        localStorage.removeItem('user')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('expires')
        localStorage.removeItem('expiresFormatted')
        window.location.reload()
      }
      console.warn(e)
    })
}

const authLink = setContext(async (request, { headers }) => {
  const expire = localStorage.getItem('expires')
  if (request.operationName === 'refreshToken') {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      return {
        headers: {
          ...headers,
          ...getHeaders()
        }
      }
    } else {
      return { headers }
    }
  }
  if (expire && Date.now() >= expire) {
    localStorage.removeItem('accessToken')
    await refreshToken()
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...getHeaders()
      // authorization: token ? `Bearer ${token}` : ''
    }
  }
})

// Create an http link:
const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL_API,
  credentials: 'include'
  /* fetch: (uri, options) => {
    options.headers = getHeaders()
    return fetch(uri, options)
  } */
})

/* console.log(headers)
// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_GRAPHQL_API,
  headers
}) */

// Cache implementation
const cache = new InMemoryCache({ addTypename: false })

// Create the apollo client
const apolloClient = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  connectToDevTools: process.env.VUE_APP_DEV_TOOLS,
  defaultOptions: {
    query: { errorPolicy: 'all' },
    mutate: { errorPolicy: 'ignore' }
  }
})

export default apolloClient
