<template>
  <div class="menu__item-wrapper">
    <slot />
    <span
      @click="toggleMenu"
      :class="classList"
      v-outside="onClickOutside"
    >
      {{ link.title }} <span class="material-icons">expand_more</span>
    </span>
  </div>
  <transition name="slide-in-up">
    <div
      class="menu__mega-dropdown"
      v-if="show"
    >
      <div
        class="menu__mega-wrapper"
        :class="{ 'menu__mega-wrapper--full': link.type === 'page' || link.type === 'wizard' }"
      >
        <div
          v-if="link.isMultiple"
          class="menu__mega-row"
          :class="{ 'menu__mega-row--page': link.type === 'page', 'menu__mega-row--wizard': link.type === 'wizard' }"
        >
          <product-item
            v-if="link.type === 'product'"
            :categories="link.children"
            @close="closeMenu()"
          />
          <page-item
            v-if="link.type === 'page'"
            :pages="link.children"
            @close="closeMenu()"
          />
        </div>
        <div
          v-else
          class="menu__mega-col"
          :class="{ 'menu__mega-col--wizard': link.type === 'wizard' }"
        >
          <wizard-item
            v-if="link.type === 'wizard'"
            :pages="link.children"
            @close="closeMenu()"
          />
          <template v-else>
            <h3>{{ link.title }}</h3>
            <ul>
              <li
                v-for="(child, i) in link.children"
                :key="i"
              >
                <router-link
                  v-if="child.url"
                  class="menu__mega-item"
                  :class="`menu__mega-item--${child.type}`"
                  :to="child.url"
                  @click="closeMenu()"
                >
                  <div class="menu__mega-item-icon">
                    <svg-sprite
                      v-if="child.icon && !child.icon.includes('.')"
                      :icon="child.icon"
                    />
                  </div>
                  <h3 class="menu__mega-item-title">
                    {{ child.title }}
                  </h3>
                  <span class="menu__mega-item-description">{{ child.description }}</span>
                </router-link>
                <span
                  v-if="child.action"
                  class="menu__mega-item-btn"
                  @click="child.action"
                >
                  {{ child.title }}
                </span>
              </li>
            </ul>
          </template>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, watch } from 'vue'
import ProductItem from '@/components/ui/megaMenu/ProductItem'
import WizardItem from '@/components/ui/megaMenu/WizardItem'
import PageItem from '@/components/ui/megaMenu/PageItem'
import { useStore } from 'vuex'
import SvgSprite from '@/components/ui/SvgSprite'

defineProps({
  link: { type: Object, required: true },
  classList: { type: String, default: 'menu__link' }
})
const emit = defineEmits(['toggle-menu', 'close'])
const store = useStore()

const toggleMenu = () => {
  show.value = !show.value
  emit('toggle-menu', show.value)
}

const show = ref(false)
const closeMenu = () => {
  show.value = false
  emit('close')
}
// eslint-disable-next-line no-return-assign
const onClickOutside = () => show.value = false
watch(show, () => {
  if (!show.value) {
    store.dispatch('closeOverlay')
  } else {
    setTimeout(function () {
      store.dispatch('showOverlay')
    }, 100)
  }
}, { immediate: true })
</script>
