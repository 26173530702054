<template>
  <div
    class="banner"
    :class="$route.name === 'home' ? 'banner--home' : ''"
    v-if="banner"
  >
    <div
      class="banner__wrapper"
      :class="classes"
    >
      <div class="banner__title-wrapper">
        <template v-if="$route.name === 'home'">
          <span class="banner__subtitle">{{ banner.title }}</span>
          <h1
            class="banner__title banner__title--home"
            :key="$route.path"
          >
            Kendinizi Keşfedin!
          </h1>
        </template>
        <h1
          v-else
          class="banner__title"
          :key="$route.path"
        >
          {{ title }}
        </h1>
      </div>
      <template v-if="$route.name === 'home'">
        <div class="banner__icon-wrapper">
          <svg-sprite
            v-if="(!banner.icon?.includes('.'))"
            class="banner__icon"
            :icon="banner.icon ?? banner.type?.icon"
          />
          <app-image
            v-else
            :image="banner.icon ?? banner.type?.icon"
            :title="banner.title ?? banner.type.title"
            :image-set="{ image: banner.icon ?? banner.type?.icon, widths: [300, 475] }"
            sizes="(min-width: 840px) 328px, (min-width: 580px) 194px, 240px"
            width="328"
            height="335"
            fetchpriority="high"
          />
        </div>
        <div class="banner__description">
          {{ banner.description ?? banner.type?.description }}
          <list-with-icon :items="bannerList" />
        </div>
      </template>
      <template v-else>
        <div
          v-if="banner.description"
          class="banner__description banner__description--full"
        >
          {{ banner.description }}
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import bannerData from '@/data/banner.json'
import { useRoute } from 'vue-router'
import { computed, inject, ref, watch } from 'vue'
import ListWithIcon from '@/components/ui/ListWithIcon'
import SvgSprite from '@/components/ui/SvgSprite'
import AppImage from '@/components/ui/AppImage'
import { useStore } from 'vuex'

const props = defineProps({ barNav: { type: Boolean, default: false } })
const route = useRoute()
const store = useStore()
const profiles = inject('profiles')
const profile = computed(() => profiles.value.find((el) => el.slug === route.path.split('/')[2]))
const banner = computed(() => (profile.value ?? bannerData[route.name]))
const title = computed(() => {
  let title = banner.value.title ?? banner.value.type?.title
  if (route.name === 'Tests') {
    if (title === '16 Profiles') {
      title += ' Kişilik Testi'
    }
    if (title === 'Değerler') {
      title += ' Testi'
    }
    if (title === 'Meslek') {
      title += ' Testi'
    }
  }

  if (route.name === 'profileInfo') {
    title = route.params.profile.toUpperCase()
  }

  if (title === 'Ürünler') {
    const product = store.getters['product/productBySlug'](route.params.productSlug)
    title = product?.title
  }
  return title
})

const bannerList = [
  { icon: 'icon-gecerli', listItem: 'Geçerli ve Güvenilir Raporlar' },
  { icon: 'icon-turkiye', listItem: 'Türkiye Normlarına Uygun' }
]

const isAuth = ref(false)
// eslint-disable-next-line no-return-assign
watch(route, () => isAuth.value = !!route.matched.filter(x => x.name === 'Authentication').length)
const classes = computed(() => {
  const test = banner.value.isFeatured ? 'banner__wrapper--test' : false
  const home = route.name === 'home' ? 'banner__wrapper--home' : false
  const yks = route.name === 'Wizard' || route.name === 'WizardList' || route.name === 'NetCalculator' || route.name === 'NetWizard' ? 'banner__wrapper--yks' : false
  const auth = isAuth.value ? 'banner__wrapper--auth' : false
  const barNav = props.barNav ? 'banner__wrapper--profile' : false
  const contracts = route.matched[0].name === 'Contracts' ? 'banner__wrapper--contracts' : false
  return [test, home, yks, auth, barNav, contracts].filter(x => x).join(' ')
})

</script>
<style lang="scss">
@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.banner {
  background: $banner-background;

  &--home {
    background-color: #EDF3FD;
  }

  &__wrapper {
    width: $grid-width-m;
    margin: auto;
    color: $color-text-dark;
    display: grid;
    grid-template-columns: 5fr 2.3fr;
    grid-column-gap: 1rem;
    min-height: 15rem;
    align-items: center;

    &--profile {
      height: 18rem;
      margin-top: 3rem;
    }

    &--home {
      width: 120rem;
      grid-column-gap: 16rem;

      & .banner__title-wrapper {
        margin-top: 8rem;
      }
    }

    &--auth {
      width: $grid-width-xss;
      min-height: 10rem;

      .banner__title {
        font-size: 2.1rem;
        font-weight: 700;
        margin: 0;
        text-align: center;

        &-wrapper {
          grid-column: 1/3;
        }
      }
    }

    &--test {
      width: $grid-width-m;
    }

    &--yks {
      width: $grid-width;
    }
  }

  &__subtitle {
    font-size: 2.1rem;
    font-weight: 500;
  }

  &__title {
    font-size: $section-title;
    font-weight: 500;

    &--home {
      font-size: 4.8rem;
      margin-bottom: 0;
      background: linear-gradient(90deg, #0060AC 0%, #23CBFF 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &-wrapper {
      grid-column: 1;
      grid-row: 1;
      margin-top: 2rem;
    }
  }

  &__icon-wrapper {
    grid-column: 2;
    grid-row: 1/3;
    justify-self: flex-end;
    display: flex;
    align-items: center;
    margin-top: 5rem;
  }

  &__icon {
    width: 20rem;
    height: 20rem;
  }

  &__description {
    font-size: $banner-desc;
    grid-column: 1;
    grid-row: 2;
    margin-bottom: 8rem;

    &--full {
      font-size: 2.1rem;
      grid-column: 1/3;
      margin-bottom: 4rem;
    }

    & hr {
      border-top: 2px solid $color-primary;
      margin: 2rem 0;
      width: 15%;
    }
  }

  @include screen(s) {
    &__wrapper {
      max-width: $grid-width-xs;
      grid-template-columns: 2fr 1fr;
      grid-column-gap: 0;
    }

    &__description {
      margin-bottom: 2rem;
    }

    &__icon-wrapper {
      margin-top: 18rem;
    }
  }

  @include screen(xs) {
    &__wrapper {
      max-width: $grid-width-xss;
      min-height: 8rem;
      grid-auto-columns: 1fr;
      grid-auto-flow: row;
      grid-template-columns: unset;

      &--profile {
        height: 12rem;
      }

      &--contracts {
        padding-top: 6rem;
      }

      &--home {
        max-width: $grid-width-xss;

        & .banner__icon-wrapper {
          grid-row: unset;
          grid-column: unset;
          width: 30rem;
          justify-self: center;
          margin-top: 0;
        }
      }
    }

    &__title {
      &--home {
        font-size: 4rem;
      }

      &-wrapper {
        text-align: center;
        margin-top: 2rem;
      }
    }

    &__subtitle {
      font-size: 2.3rem;
    }

    &__description {
      text-align: center;

      &--full {
        grid-column: 1;
      }
    }
  }
}
</style>
