<template>
  <!-- Hamburger icon -->
  <input
    class="mobile-menu"
    type="checkbox"
    id="mobile-menu"
    v-model="show"
  >
  <label
    class="hamburger"
    for="mobile-menu"
  ><span class="hamburger-line" /></label>
  <!-- Menu -->
  <nav class="menu">
    <div class="menu__action-buttons">
      <template v-if="isLoggedIn">
        <router-link
          :to="{ name: 'Dashboard' }"
          class="btn btn--outline btn--rounded-s"
          @click="closeMenu"
        >
          <span class="material-icons-outlined btn-icon">account_circle</span>
          Hesabım
        </router-link>
        <action-button
          class="btn btn--outline btn--rounded-s"
          @click="logout"
        >
          Çıkış
        </action-button>
      </template>
      <template v-else>
        <router-link
          :to="{ name: 'Login' }"
          class="btn btn--outline btn--rounded-s"
          @click="closeMenu"
        >
          Giriş
        </router-link>
        <router-link
          :to="{ name: 'Register' }"
          class="btn btn--primary btn--rounded-s"
          @click="closeMenu"
        >
          Kayıt Ol
        </router-link>
      </template>
    </div>
    <ul class="menu__list">
      <li
        v-for="(link, i) in computedLinks"
        :key="i"
        class="menu__item"
        :class="{ 'menu__dropdown': link.children }"
      >
        <router-link
          v-if="!link.children"
          :to="link.url"
          class="menu__link"
          @click="closeMenu"
        >
          <span
            v-if="link.icon"
            class="material-icons"
          >{{ link.icon }}</span>
          {{ link.title }}
        </router-link>
        <menu-item
          v-else
          :link="link"
          @close="closeMenu"
        />
      </li>
    </ul>
  </nav>
</template>

<script setup>
import MenuItem from '@/components/ui/megaMenu/MenuItem'
import { computed, inject, ref, watch } from 'vue'
import { useStore } from 'vuex'
import sourceLinks from '@/data/source-links.json'
import wizardLinks from '@/data/wizard-links.json'
import { useRouter } from 'vue-router'
import ActionButton from '@/components/ui/ActionButton'

const props = defineProps({ links: { type: Array, required: true } })
const store = useStore()
const tests = inject('profiles')
const show = ref(false)
const storeMenu = computed(() => store.getters.menu)
// eslint-disable-next-line no-return-assign
watch(storeMenu, () => show.value = storeMenu.value)
watch(show, (newState) => {
  if (newState) {
    document.body.style.setProperty('overflow', 'hidden')
  } else {
    document.body.style.removeProperty('overflow')
  }
}, { immediate: true })
// eslint-disable-next-line no-return-assign
const closeMenu = () => show.value = false

// Logout
const router = useRouter()
const user = computed(() => store.getters['auth/user'])
async function logout () {
  closeMenu()
  await router.push('/')
  await store.dispatch('auth/logout', { id: user.value.id, type: 'Logout' })
}

const isLoggedIn = computed(() => store.getters['auth/isAuthenticated'])
const categories = computed(() => store.getters['product/categories'])
const computedLinks = computed(() => {
  if (props.links.length) return props.links
  const links = []
  if (tests.value) {
    const testChildren = tests.value.map((child) => {
      if (child.isFeatured) {
        return {
          title: child.type.title,
          description: child.type.description,
          url: { name: 'Tests', params: { test: child.slug } },
          icon: child.type.icon.replace('-free', ''),
          type: child.type.type
        }
      }

      return null
    }).filter(el => el !== null)

    const testLinks = {
      title: 'Ücretsiz Testler',
      isMultiple: false,
      children: testChildren
    }

    links.push(testLinks)
  }

  if (categories.value) {
    const reportChildren = categories.value.map((category) => {
      const products = category.products.filter(el => !el.soon).map(product => {
        return {
          title: product.title,
          url: { name: 'Products', params: { productSlug: product.slug } },
          icon: product.image
        }
      })

      return {
        title: category.title,
        id: category.id,
        icon: category.image,
        products
      }
    }).filter(el => el.products.length)

    const reportLinks = {
      title: 'Raporlar',
      type: 'product',
      isMultiple: true,
      children: reportChildren
    }

    links.push(reportLinks)
  }

  links.push(sourceLinks)
  links.push(wizardLinks)
  return links
})
</script>
<style lang="scss">
@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

/* Menu Icon */
.hamburger {
  cursor: pointer;
  float: right;
  padding: 2rem 1rem;
  display: none;
  grid-column: 3;
  justify-self: flex-end;
  z-index: 10000;

  &-line {
    background: $color-primary;
    display: block;
    height: 2px;
    position: relative;
    width: 20px;
    transition: all .5s ease;

    &::before,
    &::after{
      background: $color-primary;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      transition: all .5s ease-out;
      width: 100%;
    }

    &::before {
      top: 6px;
    }

    &::after {
      top: -6px;
    }
  }
}

.mobile-menu {
  display: none;

  &:checked ~ .menu {
    left: 0;
  }

  &:checked ~ .hamburger .hamburger-line {
    transform: translateX(-50px);
    background: transparent;

    &::before {
      transform: rotate(-45deg) translate(35px, 35px);
      top:0;
    }

    &::after {
      transform: rotate(45deg) translate(35px, -35px);
      top:0;
    }
  }
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-white;
  width: 100%;
  list-style: none;
  z-index: 1000;
  overflow: hidden;
  transition: all .5s ease-out;

  &__action-buttons {
    display: none;
  }

  &__item {
    display: inline-block;
    border-bottom: 3px solid transparent;

    &:hover .menu__link {
      color: $color-primary;
    }
  }

  &__link {
    color: $color-text-darker;
    font-size: $default-font-size;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem 1.6rem;
    cursor: pointer;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 300ms;

    &:focus {
      color:inherit;
    }

    & > span {
      font-size: 2rem;
      margin: 0 1rem;
    }
  }

  &__mega-dropdown {
    position: absolute;
    background-color: $color-white;
    left: 0;
    right: 0;
    top: $header-height;
    min-height: 100%;
    border-bottom: 1px solid $color-border;
    box-shadow: inset 0 2px 6px 0 $color-shadow-lighter;
  }

  &__mega-wrapper {
    width: $grid-width-m;
    margin: auto;

    &--full {
      width: $grid-width-l;
    }

    & ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__mega-row {
    display: grid;
    grid-template-columns: 1.5fr 1fr;

    &--page {
      grid-template-columns: 2.5fr 1fr;
    }

    & .image-wrapper {
      grid-row: 1;
      margin-bottom: 1rem;
    }

    & .menu__mega-item {
      justify-items: center;
    }

    & .menu__mega-item-title {
      grid-row: 2;
      font-size: 1.6rem;
      text-transform: capitalize;
      align-self: center;
    }

    & ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__mega-col {
    padding: 2.8rem 0 2rem 0;

    & > h3 {
      margin-bottom: 1.5rem;
    }

    &.vertical {
      border-left: 1px solid $color-border;

      & > ul {
        flex-direction: column;
      }

      & .category-title {
        color: $color-primary;

        & > h3 {
          margin: 0 3rem;
        }
      }

      & .menu__mega-item-title {
        grid-row: 1;
        text-align: left;
      }
    }

    &--product {

      & > ul > li {
        margin: 0 !important;
        flex: 1;
      }

      & .menu__mega-item {
        padding: 1.3rem 1.5rem;

        &:not(.menu__mega-item--pack) {
          width: 18rem;
        }
      }

      &.vertical {
        & > ul {
          padding: 0 1.5rem;
          align-items: flex-start;
        }

        & .category-title {
          margin-bottom: 0;
        }
      }

      & .menu__mega-item--profiles:hover {
        background-color: #FFEDD2;
      }

      & .menu__mega-item--values:hover {
        background-color: #F0EBFF;
      }

      & .menu__mega-item--carrier:hover {
        background-color: #ECF7FF;
      }

      & .menu__mega-item--pack:hover {
        background-color: #DDF8F2;
      }

      & .menu__mega-item-title {
        text-align: center;
      }

      & .category-title {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 4rem;

        & > h3 {
          margin: 0 1.5rem;
        }

        & > svg {
          fill: $color-premium;
          width: 3rem;
          height: 3rem;
        }
      }

      & ul {
        padding: 1rem;

        & > li {
          margin: 1rem;
        }
      }

    }

    &--page {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      & ul {
        flex-direction: column;

        & > li:not(:last-child) {
          margin-bottom: 4rem;
        }
      }

      & .page-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1rem;
        flex: 1;
      }

      & .page-title {
        font-size: $default-font-size;
        font-weight: 500;
        color: $color-text;
        position: relative;
        padding: 0 1.5rem;
        margin-bottom: .5rem;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: -.5rem;
          width: 2rem;
          height: 2px;
          background-color: $color-border;
          transform: translate(-50%, -50%);
        }
      }

      & .menu__mega-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: $border-radius-m;
        padding: 1rem 1.5rem;

        &:hover {
          background-color: #F6F9FE;
        }

        &-title {
          font-size: 1.8rem;
          width: 100%;
          font-weight: 500;
          color: $color-text-darker;
        }

        &-description {
          font-size: 1.4rem;
        }
      }
    }

    &--wizard {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      & ul > li {
        flex: 1;

        &:not(:last-child) {
          margin-right: 1.2rem;
        }
      }

      & .menu__mega-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: $border-radius-m;
        padding: 1rem 1.5rem;

        &:hover {
          background-color: #F6F9FE;
        }

        &-icon {
          width: 5rem;
          height: 5rem;
          margin-bottom: 1.5rem;
        }

        &-title {
          font-size: 1.8rem;
          width: 100%;
          font-weight: 500;
          color: $color-text-darker;
          margin-bottom: .8rem;
        }

        &-description {
          font-size: 1.4rem;
        }
      }
    }

    &--blog {
      padding: 2.5rem 2rem;
      background-color: #F6F9FE;
      box-shadow: inset 0 2px 6px 0 $color-shadow-lighter;

      & .page-title {
        font-size: $default-font-size;
        font-weight: 500;
        color: $color-text;
        position: relative;
        margin-left: 3rem;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: -2rem;
          width: 2rem;
          height: 2px;
          background-color: $color-border;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &__mega-item {
    display: grid;
    align-items: center;
    border-radius: $border-radius-m;
    padding: 2rem;

    &:hover {
      & .menu__mega-item-icon {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      }
    }

    &--profiles {

      & svg {
        fill: #FFF;
        background-color: #FFB229;
        height: 51% !important;
      }

      &:hover {
        background-color: #FFEDD2;

        & .menu__mega-item-icon {
          border-color: #FFB229;
        }
      }
    }

    &--carrier {
      & svg {
        fill: #0072CC;
      }
      &:hover {
        background-color: #ECF7FF;

        & .menu__mega-item-icon {
          border-color: $color-primary;
        }
      }
    }

    &--values {
      & svg {
        fill: #6837FC;
      }
      &:hover {
        background-color: #F0EBFF;

        & .menu__mega-item-icon {
          border-color: #6837FC;
        }
      }
    }

    &-icon {
      grid-row: 1/3;
      width: 8rem;
      height: 8rem;
      border: 1px solid $color-border;
      border-radius: $border-radius-m;
      margin-right: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;

      & > svg {
        width: 100%;
        height: 100%;
      }
    }

    &-title {
      grid-row: 1;
      color: $color-text-darker;
      font-size: 1.8rem;
      font-weight: 500;
      margin: 0;
      align-self: flex-end;
    }

    &-description {
      grid-row: 2;
      font-size: 1.6rem;
      color: $color-text;
      align-self: start;
    }
  }
}

@include screen(m) {
  .hamburger {
    display: block;
  }

  .menu {
    left: 100%;
    top: 8rem;
    bottom: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;

    &__action-buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 2rem;

      & > .btn {
        width: 100%;
        padding: 1rem;

        &:not(:last-child) {
          margin-right: 2rem;
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 2rem;
      width: 100%;
    }

    &__item {
      width: 100%;
    }

    &__link {
      font-size: 2.2rem;

      & > span {
        font-size: 2.8rem;
      }
    }

    &__mega {
      &-dropdown {
        position: relative;
        top: 0;
        min-height: unset;
        border: unset;
        box-shadow: unset;
      }

      &-wrapper {
        width: 100%;
      }

      &-row {
        grid-template-columns: unset;
        grid-auto-columns: 1fr;
        grid-auto-flow: row;

        & .menu__mega-item-title {
          grid-row: 1/3;
          grid-column: 2;
        }
      }

      &-col {
        padding: 0;

        & > h3 {
          display: none;
        }

        ul {
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }

        &--product {
          & .menu__mega-item:not(.menu__mega-item--pack) {
            width: 100%;
          }

          & .image-wrapper.md {
            height: 7rem !important;
          }

          &.vertical {
            border: none;

            & .category-title {
              & > h3:after {
                border-color: $color-primary;
              }
            }

            & .btn {
              width: 100%;
            }
          }

          & .category-title {
            color: $color-premium;
            position: relative;
            margin: 0;

            & > h3:after {
              content: "";
              width: 45%;
              position: absolute;
              right: 0;
              transform: translate(0%, -50%);
              border-bottom: 1px solid $color-premium;
              height: unset;
              top: 50%;
              left: unset;
            }

            & > svg {
              display: none;
            }
          }
        }

        &--page {
          flex-direction: column;

          & .page-wrapper {
            padding: 0;
          }

          .page-title {
            display: none;
          }

          .menu__mega-item-title {
            color: $color-text;
          }

          & ul > li:not(:last-child) {
            margin: 0;
          }
        }

        &--blog {
          display: none;
        }
      }

      &-item {
        padding: 1rem;

        &-title {
          color: $color-text;
          grid-row: 1/3;
          align-self: center;

          .product-title__top {
            display: inline;
            padding-left: 1.5rem;
          }
        }

        &-description {
          display: none;
        }

        &-icon {
          width: 6rem;
          height: 6rem;
        }
      }
    }
  }
}
</style>
