export function useJwt (token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window.atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  const decodedJwt = JSON.parse(jsonPayload)
  const tokenAlive = () => Date.now() < decodedJwt.exp * 1000
  const isTokenAlive = tokenAlive()

  return { decodedJwt, isTokenAlive }
}
