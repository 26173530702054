<template>
  <nav class="bar-nav">
    <ul
      class="bar-nav__list"
      ref="menuItem"
    >
      <li
        v-for="(profile, i) in profiles"
        :key="i"
        class="bar-nav__item"
        data-test="bar-nav-item"
      >
        <router-link
          class="bar-nav__link"
          :to="profile.slug"
        >
          {{ profile.title }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import questionnaireProfiles from '@/graphql/questionnaireProfiles.query.graphql'
import { useRoute } from 'vue-router'

const variables = ref({ id: 2, group: true })
const { result: profileList } = useQuery(questionnaireProfiles, variables)
const profiles = computed(() => profileList?.value?.questionnaireProfiles ?? [])
const isMobile = computed(() => screen.width <= 816)
const menuItem = ref()
const route = useRoute()
const computedItem = computed(() => menuItem.value?.children)

function scrollParentToChild (child, debug = false) {
  const childLeft = child.offsetLeft
  const childWidth = child.offsetWidth
  const parentWidth = child.parentNode.offsetLeft + child.parentNode.offsetWidth
  const scrollLeft = childLeft - ((parentWidth / 2) - (childWidth / 2))
  child.parentNode.scrollLeft = scrollLeft
  if (debug) console.log({ childLeft, childWidth, parentWidth, scrollLeft })
}

watch([computedItem, route], () => {
  if (isMobile.value && computedItem.value && route.params.profile) {
    const items = Array.from(computedItem.value)
    const keys = Object.keys(items)
    const filtered = keys.filter(x => items[x].textContent === route.params.profile.toUpperCase())
    const index = keys.indexOf(filtered[0])
    if (index >= 0) scrollParentToChild(items[index])
  }
}, { immediate: true, deep: true })

</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.bar-nav {
  width: 100%;
  background-color: #E4EDFB;
  border-bottom: 1px solid $color-border;

  &__list {
    display: flex;
    text-align: center;
    white-space: nowrap;
    align-items: center;
    width: $grid-width;
    margin: auto;
  }

  &__link {
    color: $color-text-darker;
    font-size: 2.1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    padding: 1.2rem 1.8rem;
    cursor: pointer;
    overflow: hidden;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 300ms;

    & > span {
      padding: 0 1rem;
    }

    &:hover {
      color: $color-white;
    }

    &.router-link-exact-active {
      background-color: #BADFFF;
      color: $color-primary;
      position: relative;

      &::after {
        content: "";
        height: 2rem;
        background-color: $color-primary;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 2rem;
        transform: translate(0, 60%);
      }
    }
  }

  &__item {
    width: 100%;
    position: relative;

    &:hover {
      background-color: $color-secondary-dark;

      > ul {
        transform: rotateX(0deg);
        visibility: visible;
      }
    }

    > ul {
      width: 100%;
      background: #fff none repeat scroll 0 0;
      box-shadow: 0 0 2rem .4px rgba(0, 0, 0, .1);
      display: block;
      left: 0;
      position: absolute;
      top: 100%;
      transform: rotateX(90deg);
      transform-origin: center top 0;
      transition: all .5s ease 0s;
      visibility: hidden;
      z-index: 1100;

      li {
        display: block;
        position: relative;
        text-align: left;
        line-height: 1;

        a {
          display: block;
          color: $color-black;
          background-color: $color-white;
          font-weight: 400;
          font-size: 1.6rem;
          letter-spacing: .2px;
          text-transform: capitalize;
          padding: 1rem;

          &:hover {
            color: $color-white;
            background-color: $color-primary;
          }
        }
      }
    }
  }

  @include screen(m) {
    width: 100%;

    &__list {
      width: unset;
      overflow-x: scroll;
    }
  }
}

/* Slide-in-up animation*/
.slide-in-up-enter-active, .slide-in-up-leave-active {
  transition: all .5s;
  transform: translateY(0);
}

.slide-in-up-enter-from, .slide-in-up-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
