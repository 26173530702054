import outside from '@/directives/outside'
import autofocus from '@/directives/autofocus'

// const myOtherDirective = {
//   ...
// }

export default {
  install (Vue) {
    Vue.directive('outside', outside)
    Vue.directive('autofocus', autofocus)
    // Vue.directive('other-directive', myOtherDirective)
  }
}
