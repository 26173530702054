<template>
  <div
    id="body-overlay"
    v-if="overlay"
  />
  <app-header :social-media="socialMedia" />
  <notification-list />
  <page-banner :bar-nav="false" />
  <main class="contracts">
    <div class="contracts__sidebar sticky sticky--web">
      <side-navigation
        :links="links"
        :pills="isMobile"
        :more="true"
        transparent
      />
    </div>
    <div class="contracts__content">
      <router-view v-slot="{ Component }">
        <template v-if="Component">
          <transition
            name="fade-in-left"
            mode="out-in"
          >
            <component
              :is="Component"
              :key="$route.path"
            />
          </transition>
        </template>
      </router-view>
    </div>
  </main>
  <app-footer />
</template>

<script setup>
import { computed } from 'vue'
import AppHeader from '@/layout/DefaultHeader'
import AppFooter from '@/layout/DefaultFooter'
import PageBanner from '@/layout/PageBanner'
import NotificationList from '@/components/notification/NotificationList'
import SideNavigation from '@/layout/SideNavigation'
import socialMediaData from '@/data/social-media.json'
import { useStore } from 'vuex'

const isMobile = computed(() => screen.width <= 816)
const socialMedia = socialMediaData
const store = useStore()
const overlay = computed(() => store.getters.overlay)
const links = [
  {
    title: 'Kişisel Verileri Koruma Politikamız',
    shortTitle: 'Kişisel Verileri Koruma Politikamız',
    url: 'sozlesmeler/kvkk'
  },
  {
    title: 'Üyelik Sözleşmesi',
    shortTitle: 'Üyelik Sözleşmesi',
    url: 'sozlesmeler/uyelik-sozlesmesi'
  },
  {
    title: 'Kullanım Koşulları',
    shortTitle: 'Kullanım Koşulları',
    url: 'sozlesmeler/kullanim-kosullari'
  },
  {
    title: 'Gizlilik Politikamız',
    shortTitle: 'Gizlilik Politikamız',
    url: 'sozlesmeler/gizlilik-politikamiz'
  },
  {
    title: 'Çerez Politikamız',
    shortTitle: 'Çerez Politikamız',
    url: 'sozlesmeler/cerez-politikamiz'
  },
  {
    title: 'Aydınlatma Metni',
    shortTitle: 'Aydınlatma Metni',
    url: 'sozlesmeler/aydinlatma-metni'
  },
  {
    title: 'Veri Sahibi Başvuru Yönergesi',
    shortTitle: 'Veri Sahibi Başvuru Yönergesi',
    url: 'sozlesmeler/basvuru-yonergesi'
  },
  {
    title: 'Açık Rıza Metni',
    shortTitle: 'Açık Rıza Metni',
    url: 'sozlesmeler/acik-riza-metni'
  }
]
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.contracts {
  display: flex;
  margin-bottom: 4rem;

  &__sidebar {
    margin-right: 3rem;
  }

  &__content {
    font-size: 1.4rem;
    padding: 3rem;
    border: 1px solid $color-border;
    border-radius: $border-radius-m;

    h1, h2, h3 {
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }

    p, ul {
      margin-bottom: 1rem;
    }

    ul, li {
      list-style: inside disc;
    }
  }

  @include screen(xs) {
    &__sidebar {
      position: fixed !important;
      margin: 0;
    }
  }
}
</style>
