<template>
  <div
    id="body-overlay"
    v-if="overlay"
  />
  <app-header
    :social-media="socialMedia"
    :bar-nav="barNav"
  />
  <notification-list />
  <page-banner :bar-nav="barNav" />
  <router-view v-slot="{ Component, route }">
    <template v-if="Component">
      <transition
        name="fade-in-left"
        mode="out-in"
      >
        <component
          :is="Component"
          :key="route.path"
        />
      </transition>
    </template>
  </router-view>
  <app-footer />
</template>

<script setup>
import socialMediaData from '@/data/social-media.json'
import AppHeader from '@/layout/DefaultHeader'
import PageBanner from '@/layout/PageBanner'
import AppFooter from '@/layout/DefaultFooter'
import { computed } from 'vue'
import { useStore } from 'vuex'
import NotificationList from '@/components/notification/NotificationList'

const socialMedia = socialMediaData
const store = useStore()
const barNav = computed(() => store.getters.barNav)
const overlay = computed(() => store.getters.overlay)
</script>
