<template>
  <h3
    class="product-title"
    :class="classes"
  >
    <template v-if="unformatted">
      {{ title }}
    </template>
    <template v-else>
      <span class="product-title__top">
        <svg-sprite
          v-if="icon"
          icon="icon-premium"
          class="product-title__icon"
        />
        <span
          v-if="colorful"
          class="product-title__premium-text product-title__premium-text--colorful"
        >{{ formattedLabel[0] }}</span>
        <span
          v-else
          class="product-title__premium-text"
        >{{ formattedLabel[0] }}</span>
      </span>
      {{ formattedLabel[1] }}
    </template>
  </h3>
</template>

<script setup>
import SvgSprite from '@/components/ui/SvgSprite'
import { computed } from 'vue'

const props = defineProps({
  title: { type: String, required: true },
  size: { type: String, default: '' },
  position: { type: String, default: 'center' },
  icon: { type: Boolean, default: false },
  colorful: { type: Boolean, default: false },
  unformatted: { type: Boolean, default: false }
})
const classes = computed(() => {
  const size = props.size ? `product-title--${props.size}` : false
  const position = props.position === 'center' ? 'product-title--center' : false
  return [size, position].filter(x => x).join(' ')
})
const formattedLabel = computed(() => {
  if (props.colorful) {
    return props.title.split(/\s(.*)/s)
  } else {
    return props.title.split(/\s(.*)/s)
  }
})
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";

.product-title {
  font-size: 2.8rem;
  margin-bottom: 0;

  &--center {
    text-align: center;

    & .product-title__top {
      justify-content: center;
    }
  }

  &--sm {
    font-size: 2.1rem;

    & .product-title__premium-text {
      font-size: 1.6rem;
    }
  }

  &--xs {
    font-size: 1.6rem;

    & .product-title__premium-text {
      font-size: 1.6rem;
    }
  }

  &__top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    fill: $color-premium;
    margin-right: .6rem;
  }

  &__premium-text {
    font-size: 1.8rem;

    &--colorful {
      color: $color-premium;
    }
  }
}
</style>
