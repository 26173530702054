<template>
  <header class="header header--light sticky">
    <div class="header__wrapper">
      <div class="header__logo">
        <router-link
          to="/"
          @click="closeMenu"
        >
          <app-image
            class="header__logo-img"
            width="225px"
            height="40px"
            image="logo-dark.svg"
            title="Logo"
          />
        </router-link>
      </div>
      <mega-menu
        :links="links"
        :key="key"
      />
      <div
        class="header__action-wrapper"
      >
        <router-link
          :to="{ name: 'Cart' }"
          class="btn btn--primary btn--square btn--icon"
          @click="closeMenu"
        >
          <svg-sprite
            class="btn-icon"
            icon="icon-cart"
          />
          <span class="btn-badge">{{ cartQuantity }}</span>
        </router-link>
        <router-link
          :to="{ name: 'Dashboard' }"
          class="btn btn--outline btn--rounded-s"
          v-if="isLoggedIn"
        >
          <span class="material-icons-outlined btn-icon">account_circle</span>
          Hesabım
        </router-link>
        <router-link
          :to="{ name: 'Login' }"
          class="btn btn--outline btn--rounded-s"
          v-if="!isLoggedIn"
        >
          Giriş
        </router-link>
        <router-link
          :to="{ name: 'Register' }"
          class="btn btn--primary btn--rounded-s"
          v-if="!isLoggedIn"
        >
          Kayıt Ol
        </router-link>
      </div>
    </div>
    <bar-navigation v-if="barNav" />
  </header>
</template>

<script setup>
import BarNavigation from '@/layout/BarNavigation'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import MegaMenu from '@/components/ui/megaMenu/AppMenu'
import SvgSprite from '@/components/ui/SvgSprite'
import AppImage from '@/components/ui/AppImage'

defineProps({
  links: { type: Array, default: () => [] },
  socialMedia: { type: Array, default: () => [] },
  barNav: { type: Boolean, default: false }
})

const store = useStore()
const key = ref(1)
const closeMenu = () => key.value++ && store.dispatch('closeMenu')
const cartQuantity = computed(() => store.getters['cart/cartQuantity'])
const isLoggedIn = computed(() => store.getters['auth/isAuthenticated'])
</script>
