import { configure, defineRule, ErrorMessage, Field, Form } from 'vee-validate'
import { localize } from '@vee-validate/i18n'
import {
  alpha_spaces as alphaSpaces,
  alpha_dash as alphaDash,
  numeric,
  confirmed,
  email,
  max,
  max_value as maxValue,
  min,
  min_value as minValue,
  not_one_of as excluded,
  required,
  regex,
  between
} from '@vee-validate/rules'

export default {
  install (app) {
    app.component('VeeForm', Form)
    app.component('VeeField', Field)
    app.component('ErrorMessage', ErrorMessage)

    defineRule('required', required)
    defineRule('tos', required)
    defineRule('regex', regex)
    defineRule('min', min)
    defineRule('max', max)
    defineRule('between', between)
    defineRule('email', email)
    defineRule('alphaSpaces', alphaSpaces)
    defineRule('alphaDash', alphaDash)
    defineRule('numeric', numeric)
    defineRule('confirmed', confirmed)
    defineRule('minValue', minValue)
    defineRule('maxValue', maxValue)
    defineRule('excluded', excluded)
    defineRule('phone', value => {
      // Field is empty, should pass
      if (!value || !value.length) {
        return true
      }

      // Check if phone number
      return /^(5[345][0-9]+\d{7})$/.test(value)
    })

    configure({
      generateMessage: localize({
        en: {
          messages: {
            required: 'The {field} is required.',
            min: 'The field is too short.',
            max: 'The field is too long.',
            alphaSpaces: 'The field may only contain alphabetical characters and space.',
            email: 'The field must be valid email address.',
            phone: 'The field must be valid phone number.',
            minValue: 'The field is too low.',
            maxValue: 'The field is too high.',
            excluded: 'You are not allowed to use this value for the field.',
            confirmed: 'The Passwords dont match.',
            tos: 'You must accept the term of services'
          },
          names: {
            name: 'Name',
            lastName: 'Lastname',
            email: 'E-Mail',
            phone: 'Phone',
            cityId: 'City',
            districtId: 'District',
            address: 'Address',
            addressTitle: 'Address Title'
          },
          fields: {
            name: { required: 'Le nom est requis' },
            lastName: { required: 'Le nom est requis' },
            email: { required: 'Le nom est requis' },
            phone: { required: 'Le nom est requis' },
            cityId: { required: 'Lütfen ilinizi seçiniz.' },
            districtId: { required: 'Lütfen ilçenizi seçiniz.' },
            address: {
              required: 'Adresiniz 10-250 harf, rakam veya boşluk içermelidir.',
              alphaDash: 'Adresiniz 10-250 harf, rakam veya boşluk içermelidir.',
              min: 'Adresiniz 10-250 harf, rakam veya boşluk içermelidir.',
              max: 'Adresiniz 10-250 harf, rakam veya boşluk içermelidir.'
            },
            addressTitle: {
              required: 'Adres başlığınız 1-20 karakter içermelidir.',
              alphaDash: 'Adres başlığınız 1-20 karakter içermelidir.',
              min: 'Adres başlığınız 1-20 karakter içermelidir.',
              max: 'Adres başlığınız 1-20 karakter içermelidir.'
            },
            vkn: {
              required: 'VKN 10-11 karakter içermelidir.',
              numeric: 'VKN 10-11 karakter içermelidir.',
              min: 'VKN 10-11 karakter içermelidir.',
              max: 'VKN 10-11 karakter içermelidir.'
            },
            taxAuthority: {
              required: '5-10 karakter içermelidir.',
              alphaDash: '5-10 karakter içermelidir.',
              min: '5-10 karakter içermelidir.',
              max: '5-10 karakter içermelidir.'
            },
            companyName: {
              required: '5-100 karakter içermelidir.',
              alphaDash: '5-100 karakter içermelidir.',
              min: '5-100 karakter içermelidir.',
              max: '5-100 karakter içermelidir.'
            }
          }
        },
        tr: {
          messages: {
            required: '{field} zorunludur.',
            min: 'The field is too short.',
            max: 'The field is too long.',
            alphaSpaces: 'The field may only contain alphabetical characters and space.',
            email: 'Lütfen geçerli bir e-posta adresi giriniz.',
            phone: 'Lütfen cep telefonu numarınızı giriniz',
            minValue: 'The field is too low.',
            maxValue: 'The field is too high.',
            excluded: 'You are not allowed to use this value for the field.',
            confirmed: 'Şifreniz uyumlu değil.',
            tos: 'You must accept the term of services'
          },
          names: {
            age: 'Yaş',
            name: 'Ad',
            parentName: 'Ad',
            lastName: 'Soyad',
            parentLastName: 'Soyad',
            email: 'E-Posta',
            parentEmail: 'E-Posta',
            phone: 'Cep Telefonu',
            parentPhone: 'Cep Telefonu',
            password: 'Şifre',
            cityId: 'İl',
            districtId: 'İlçe',
            address: 'Adres',
            addressTitle: 'Adres Başlığı',
            vkn: 'Vergi Numarası',
            taxAuthority: 'Vergi Dairesi',
            companyName: 'Şirket Adı'
          },
          fields: {
            age: {
              required: 'Lütfen yaşınızı giriniz.',
              maxValue: 'Lütfen geçerli bir yaş giriniz.'
            },
            name: {
              required: 'Lütfen adınızı giriniz.',
              alphaSpaces: 'Lütfen geçerli bir ad giriniz.',
              min: 'Adınız 2 karakterden kısa olamaz.',
              max: 'Adınız 50 karakterden uzun olamaz.'
            },
            parentName: {
              required: 'Lütfen adınızı giriniz.',
              alphaSpaces: 'Lütfen geçerli bir ad giriniz.',
              min: 'Adınız 2 karakterden kısa olamaz.',
              max: 'Adınız 50 karakterden uzun olamaz.'
            },
            lastName: {
              required: 'Lütfen soyadınızı giriniz.',
              alphaSpaces: 'Lütfen geçerli bir soyad giriniz.',
              min: 'Soyadınız 2 karakterden kısa olamaz.',
              max: 'Soyadınız 50 karakterden uzun olamaz.'
            },
            parentLastName: {
              required: 'Lütfen soyadınızı giriniz.',
              alphaSpaces: 'Lütfen geçerli bir soyad giriniz.',
              min: 'Soyadınız 2 karakterden kısa olamaz.',
              max: 'Soyadınız 50 karakterden uzun olamaz.'
            },
            email: {
              required: 'Lütfen e-posta adresinizi giriniz.',
              email: 'Lütfen geçerli bir e-posta adresi giriniz.'
            },
            parentEmail: {
              required: 'Lütfen e-posta adresini giriniz.',
              email: 'Lütfen geçerli bir e-posta adresi giriniz.'
            },
            phone: {
              phone: 'Lütfen geçerli bir telefon numarası giriniz.',
              required: 'Lütfen telefon numaranızı giriniz.',
              min: 'Telefon numarası 10 karakterden az olamaz.',
              max: 'Telefon numarası 10 karakterden uzun olamaz.'
            },
            parentPhone: {
              phone: 'Lütfen geçerli bir telefon numarası giriniz.',
              required: 'Lütfen telefon numaranızı giriniz.',
              min: 'Telefon numarası 10 karakterden az olamaz.',
              max: 'Soyadınız 50 karakterden uzun olamaz.'
            },
            password: {
              required: 'Şifreniz <strong>en az 8 karakter</strong> olmalı, <strong>harf</strong> ve <strong>rakam</strong> içermelidir.',
              min: 'Şifreniz <strong>en az 8 karakter</strong> olmalı, <strong>harf</strong> ve <strong>rakam</strong> içermelidir.',
              max: 'Şifreniz <strong>en az 8 karakter</strong> olmalı, <strong>harf</strong> ve <strong>rakam</strong> içermelidir.'
            },
            cityId: { required: 'Lütfen ilinizi seçiniz.' },
            districtId: { required: 'Lütfen ilçenizi seçiniz.' },
            address: {
              required: 'Adresiniz 10-250 harf, rakam veya boşluk içermelidir.',
              alphaDash: 'Adresiniz 10-250 harf, rakam veya boşluk içermelidir.',
              min: 'Adresiniz 10-250 harf, rakam veya boşluk içermelidir.',
              max: 'Adresiniz 10-250 harf, rakam veya boşluk içermelidir.'
            },
            addressTitle: {
              required: 'Adres başlığınız 1-20 karakter içermelidir.',
              alphaDash: 'Adres başlığınız 1-20 karakter içermelidir.',
              min: 'Adres başlığınız 1-20 karakter içermelidir.',
              max: 'Adres başlığınız 1-20 karakter içermelidir.'
            },
            vkn: {
              required: 'VKN 10-11 karakter içermelidir.',
              numeric: 'VKN 10-11 karakter içermelidir.',
              min: 'VKN 10-11 karakter içermelidir.',
              max: 'VKN 10-11 karakter içermelidir.'
            },
            taxAuthority: {
              required: '5-10 karakter içermelidir.',
              alphaDash: '5-10 karakter içermelidir.',
              min: '5-10 karakter içermelidir.',
              max: '5-10 karakter içermelidir.'
            },
            companyName: {
              required: '5-100 karakter içermelidir.',
              alphaDash: '5-100 karakter içermelidir.',
              min: '5-100 karakter içermelidir.',
              max: '5-100 karakter içermelidir.'
            }
          }
        }
      })
    })
  }
}
