<template>
  <user-header />
  <notification-list />
  <main>
    <div class="container p-no">
      <div class="flex-grid no-gutter overflow">
        <div class="col no-gutter">
          <component
            v-if="!isMobile"
            :is="sidebar.comp"
            :links="userLinks"
            :report="currentReport"
          />
          <template v-else>
            <report-navigation
              v-if="sidebar.name === 'ReportNavigation'"
              :report="currentReport"
              :links="report"
            />
            <side-navigation :links="userLinkData" />
          </template>
        </div>
        <div class="col-4">
          <router-view v-slot="{ Component }">
            <template v-if="Component">
              <transition
                name="fade-in-left"
                mode="out-in"
              >
                <component
                  :is="Component"
                  :key="route.path"
                />
              </transition>
            </template>
          </router-view>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import userLinkData from '@/data/user-links.json'
import { computed, provide, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useQuery } from '@vue/apollo-composable'
import reportSections from '@/graphql/reportSections.query.graphql'
import reportsTable from '@/graphql/reportsTable.query.graphql'
import UserHeader from '@/layout/UserHeader'
import NotificationList from '@/components/notification/NotificationList'
import SideNavigation from '@/layout/SideNavigation'
import ReportNavigation from '@/layout/ReportNavigation'

const components = [
  { name: 'SideNavigation', comp: SideNavigation },
  { name: 'ReportNavigation', comp: ReportNavigation }
]
const isMobile = computed(() => screen.width <= 816)
const route = useRoute()
const router = useRouter()
const sidebar = computed(() => components.find(comp => comp.name === route.meta.sidebar) || components[0])
const id = computed(() => route.params.id ? route.params.id : false)
const variables = ref({})
const queryOptions = ref({ enabled: false })
const query = useQuery(reportSections, variables, queryOptions)
const report = computed(() => query.result?.value?.report ?? [])

const { result: reportList, refetch } = useQuery(reportsTable)
const reports = computed(() => reportList?.value?.userReports ?? [])
const currentReport = computed(() => reports.value.find(el => el.id === id.value))

if (sidebar.value.name === 'ReportNavigation') {
  queryOptions.value.enabled = true
  variables.value.id = id
}

watch(id, () => {
  if (id.value && sidebar.value.name === 'ReportNavigation') {
    queryOptions.value.enabled = true
    variables.value.id = id
  } else {
    queryOptions.value.enabled = false
  }
})

router.beforeEach((to) => {
  if (to.name === 'Reports' || to.name === 'Dashboard') {
    refetch()
  }
})
provide('reportSections', report)
provide('reports', reports)

const userLinks = computed(() => sidebar.value.name === 'SideNavigation' ? userLinkData : report.value)
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

main {
  background-color: #FCFCFC;
  width: 100%;

  @include screen(s) {
    background-color: $color-white;
  }
}
</style>
